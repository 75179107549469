import React from "react";
import styles from "./legal.module.scss";
const PrivacyPolicy = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <span className={`${styles["main-title"]} blue-text`}>Privacy Policy</span>
        <span className={`${styles.text} ${styles["text-bold"]} ${styles["text-italic"]}`}>The Sentinel Privacy Policy was updated on July 31, 2023.</span>
        <span className={styles.text}>
          In the following policy, Sentinel refers to the service offered by CAPYBARA PUBLISHING LTD. (the “Company” or “We”) through the{" "}
          <a
            href="https://sentinel.co"
            target="_blank"
            rel="noreferrer"
          >
            Sentinel.co
          </a>{" "}
          website and mobile applications (the “Service”). This Privacy Policy explains what information we collect through your access and use of our Service the use we make of
          such information; and the security level we provide for protecting such information.
        </span>
        <span className={styles.title}>Legal Framework</span>
        <span className={styles.text}>The Company is domiciled in Scotland and thus governed by the laws and regulations of the Scotland and United Kingdom.</span>
        <span className={styles.title}>Data Collection</span>
        <span className={styles.text}>In most cases, we don't need your personal information to provide our services.</span>
        <span className={styles.text}>
          Visiting our website: We use specific analytics software (Google Analytics, Matomo & etc.) provided by third-parties only on Sentinel main website to collect some
          information (device type, language and etc.) in order to improve our services. Other our websites have no analytics software at all to ensure users’ privacy.
        </span>
        <span className={styles.text}>
          <span className={styles["text-bold"]}>SentinelVPN:</span> Our VPN nodes do not collect any traffic sent by your devices by default. We do not collect IP addresses, device
          information & etc.
        </span>

        <span className={styles.text}>
          <span className={styles["text-bold"]}>Native Applications:</span> Our When you use our native applications, we (or the mobile app platform providers) may collect certain
          information in addition to the information mentioned elsewhere in this Policy. We may use mobile error analytics software to send crash information to our developers so
          that we can fix bugs rapidly. Some operating systems may also collect and aggregate anonymous statistics if you opt-in. Therefore, such data may be governed by the
          privacy policy and terms and conditions of such software vendors. None of the software on our apps will ever access or track any location-based information from your
          device at any time.
        </span>

        <span className={styles.title}>Data Use</span>
        <span className={styles.text}>
          We do not have any advertising on our websites & applications. Any data that we do have will never be shared except under the circumstances described below in the Data
          Disclosure Section. We do NOT do any analysis on the limited data we do possess.
        </span>

        <span className={styles.title}>Data Storage</span>
        <span className={styles.text}>
          All servers used in connection with the provisioning of the Service are located in the United Kingdom, European Union or United States of America. Only Sentinel employees
          have access to the servers. Data is ALWAYS transferred in encrypted format to our servers. All application specific data (e.g. SentinelVPN traffic) is ALWAYS encrypted
          and we do not possess the ability to access any user encrypted data on the production servers.
        </span>

        <span className={styles.title}>Right to Access, Rectification, Erasure and Portability</span>
        <span className={styles.text}>
          Through the Service, you can directly access, edit, delete or export personal data (according to the EU General Data Protection Regulation) processed by the Company in
          your use of the Service.
        </span>

        <span className={styles.title}>Data Retention</span>
        <span className={styles.text}>
          When a Sentinel account is closed, data is immediately deleted from production servers. Active accounts will have data retained indefinitely.
        </span>

        <span className={styles.title}>Data Disclosure</span>
        <span className={styles.text}>
          Usually, we do not disclose your account information (except e-mail address) to third-party companies & government authorities. We do not provide any information unless
          we have received an original copy of the court order by registered post or in person.
        </span>
        <span className={styles.text}>
          If a request is made for user’s application-specific encrypted data, that Sentinel does not possess the ability to decrypt, the fully encrypted data may be turned over.
          However, Sentinel Apps uses military-grade encryption algorithms & encrypted data cannot be decrypted be government authorities.
        </span>
        <span className={styles.text}>
          If permitted by law, Sentinel will always contact a user first before any data disclosure. Sentinel may contest court orders. In such situations, the Company will not
          comply with the court order until all legal or other remedies have been exhausted. Therefore, not all court orders will lead to data disclosure.
        </span>

        <span className={styles.title}>Modifications to Privacy Policy</span>
        <span className={styles.text}>
          Sentinel reserves the right to periodically review and change this policy from time to time and we will notify users who have enabled the notification preference about
          changes to our Privacy Policy. Continued use of the Service will be deemed as acceptance of such changes.
        </span>

        <span className={styles.title}>Applicable Law</span>
        <span className={styles.text}>
          This Agreement shall be governed in all respects by the substantive laws of the United Kingdom. Any controversy, claim, or dispute arising out of or relating to the
          Agreement shall be subject to the jurisdiction of the competent court being expressly reserved.
        </span>

        <span className={styles.title}>Warrant Canary</span>
        <span className={styles.text}>
          Sentinel has not produced any information in response to a secret subpoena and has not built “backdoors” for any government into our products. All government requests for
          user data need to come through the front door (i.e., by serving valid legal process upon Sentinel).
        </span>

        <span className={styles.title}>Contact information</span>
        <span className={styles.text}>CAPYBARA PUBLISHING LTD. (reg no. 15786216)</span>
        <span className={styles.text}>85 Great Portland Street</span>
        <span className={styles.text}>London, England</span>
        <span className={styles.text}>W1W 7LT</span>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
