import React from "react";
import styles from "./legal.module.scss";

const TOS = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <span className={`${styles["main-title"]} blue-text`}>Terms and Conditions</span>
        <span className={styles.title}>Ownership of Site; Agreement to Terms of Use</span>
        <span className={styles.text}>
          These Terms and Conditions of Use (the “Terms of Use”) apply to the Sentinel web site located at <a href="/">sentinel.co</a>, and all associated sites linked to{" "}
          <a href="/">sentinel.co</a> by Sentinel, its subsidiaries and affiliates, including Sentinel sites around the world (collectively, the “Site”). The Sentinel is the
          property of Sentinel Ltd. (“Sentinel") and its licensors. BY USING THE SITE, YOU AGREE TO THESE TERMS OF USE; IF YOU DO NOT AGREE, DO NOT USE THE SITE.
        </span>
        <span className={styles.text}>
          Sentinel reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time. It is your responsibility to check
          these Terms of Use periodically for changes. Your continued use of the Site following the posting of changes will mean that you accept and agree to the changes. As long
          as you comply with these Terms of Use, Sentinel grants you a personal, non-exclusive, non-transferable, limited privilege to enter and use the Site.
        </span>
        <span className={styles.title}>Content</span>
        <span className={styles.text}>
          All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code (collectively, “Content”), including but
          not limited to the design, structure, selection, coordination, expression, “look and feel” and arrangement of such Content, contained on the Site is owned, controlled or
          licensed by or to Sentinel, and is protected by trade dress, copyright, patent and trademark laws, and various other intellectual property rights and unfair competition
          laws. Except as expressly provided in these Terms of Use, no part of the Site and no Content may be copied, reproduced, republished, uploaded, posted, publicly displayed,
          encoded, translated, transmitted or distributed in any way (including “mirroring”) to any other computer, server, Web site or other medium for publication or distribution
          or for any commercial enterprise, without Sentinel express prior written consent. You may use information on Sentinel products and services (such as data sheets,
          knowledge base articles, and similar materials) purposely made available by Sentinel for downloading from the Site, provided that you (1) not remove any proprietary
          notice language in all copies of such documents, (2) use such information only for your personal, non-commercial informational purpose and do not copy or post such
          information on any networked computer or broadcast it in any media, (3) make no modifications to any such information, and (4) not make any additional representations or
          warranties relating to such documents.
        </span>
        <span className={styles.title}>Your Use of the Site</span>
        <span className={styles.text}>
          You may not use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program, algorithm or methodology, or any similar or equivalent manual
          process, to access, acquire, copy or monitor any portion of the Site or any Content, or in any way reproduce or circumvent the navigational structure or presentation of
          the Site or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Site. Sentinel
          reserves the right to bar any such activity. You may not attempt to gain unauthorized access to any portion or feature of the Site, or any other systems or networks
          connected to the Site or to any Sentinel server, or to any of the services offered on or through the Site, by hacking, password “mining” or any other illegitimate means.
          You may not probe, scan or test the vulnerability of the Site or any network connected to the Site, nor breach the security or authentication measures on the Site or any
          network connected to the Site. You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to the Site, or any other customer of
          Sentinel, including any Sentinel account not owned by you, to its source, or exploit the Site or any service or information made available or offered by or through the
          Site, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as
          provided for by the Site. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Site or
          Sentinel systems or networks, or any systems or networks connected to the Site or to Sentinel. You agree not to use any device, software or routine to interfere or
          attempt to interfere with the proper working of the Site or any transaction being conducted on the Site, or with any other person’s use of the Site. You may not forge
          headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to Sentinel on or through the Site or any service
          offered on or through the Site. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity. You may not use the
          Site or any Content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which
          infringes the rights of Sentinel or others.
        </span>
        <span className={styles.title}>Purchases; Other Terms and Conditions</span>
        <span className={styles.text}>
          Additional terms and conditions may apply to purchases of goods or services and to specific portions or features of the Site, including contests, promotions or other
          similar features, all of which terms are made a part of these Terms of Use by this reference. You agree to abide by such other terms and conditions, including where
          applicable representing that you are of sufficient legal age to use or participate in such service or feature. If there is a conflict between these Terms of Use and the
          terms posted for or applicable to a specific portion of the Site or for any service offered on or through the Site, the latter terms shall control with respect to your
          use of that portion of the Site or the specific service. Sentinel obligations, if any, with regard to its products and services are governed solely by the agreements
          pursuant to which they are provided, and nothing on this Site should be construed to alter such agreements. Sentinel may make changes to any products or services offered
          on the Site, or to the applicable prices for any such products or services, at any time, without notice. The materials on the Site with respect to products and services
          may be out of date, and Sentinel makes no commitment to update the materials on the Site with respect to such products and services.
        </span>
        <span className={styles.title}>Privacy</span>
        <span className={styles.text}>
          Sentinel Privacy Policy applies to use of this Site, and its terms are made a part of these Terms of Use by this reference. To view Sentinel Privacy Policy, visit{" "}
          <a
            href="https://basedapps.co.uk/privacy.html"
            target="_blank"
            rel="noreferrer"
          >
            basedapps.co.uk/privacy.html
          </a>
          . Additionally, by using the Site, you acknowledge and agree that Internet transmissions are never completely private or secure. You understand that any message or
          information you send to the Site may be read or intercepted by others, even if there is a special notice that a particular transmission (for example, credit card
          information) is encrypted.
        </span>
        <span className={styles.title}>Links to Other Sites and to the Sentinel Site</span>
        <span className={styles.text}>
          This Site may contain links to other independent third-party Web sites (“Linked Sites”). These Linked Sites are provided solely as a convenience to our visitors. Such
          Linked Sites are not under Sentinel control, and Sentinel is not responsible for and does not endorse the content of such Linked Sites, including any information or
          materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites.
        </span>
        <span className={styles.title}>Disclaimers</span>
        <span className={styles.text}>
          Sentinel DOES NOT PROMISE THAT THE SITE OR ANY CONTENT, SERVICE OR FEATURE OF THE SITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT
          YOUR USE OF THE SITE WILL PROVIDE SPECIFIC RESULTS. THE SITE AND ITS CONTENT ARE DELIVERED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. ALL INFORMATION PROVIDED ON THE SITE IS
          SUBJECT TO CHANGE WITHOUT NOTICE. Sentinel CANNOT ENSURE THAT ANY FILES OR OTHER DATA YOU DOWNLOAD FROM THE SITE WILL BE FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE
          FEATURES. Sentinel DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
          PURPOSE. Sentinel DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF THE SITE AND/OR
          ANY Sentinel SERVICES. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE SITE AND ANY LINKED SITES. YOUR SOLE REMEDY AGAINST Sentinel FOR DISSATISFACTION WITH THE SITE
          OR ANY CONTENT IS TO STOP USING THE SITE OR ANY SUCH CONTENT. THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES. The above disclaimer applies to any
          damages, liability or injuries caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus,
          communication line failure, theft or destruction of or unauthorized access to, alteration of, or use, whether for breach of contract, tort, negligence or any other cause
          of action. Sentinel reserves the right to do any of the following, at any time, without notice: (1) to modify, suspend or terminate operation of or access to the Site, or
          any portion of the Site, for any reason; (2) to modify or change the Site, or any portion of the Site, and any applicable policies or terms; and (3) to interrupt the
          operation of the Site, or any portion of the Site, as necessary to perform routine or non-routine maintenance, error correction, or other changes.
        </span>
        <span className={styles.title}>Limitation of Liability</span>
        <span className={styles.text}>
          Except where prohibited by law, in no event will Sentinel be liable to you for any indirect, consequential, exemplary, incidental or punitive damages, including lost
          profits, even if Sentinel has been advised of the possibility of such damages. If, notwithstanding the other provisions of these Terms of Use, Sentinel is found to be
          liable to you for any damage or loss which arises out of or is in any way connected with your use of the Site or any Content, Sentinel liability shall in no event exceed
          the greater of US$5.00. Some jurisdictions do not allow limitations of liability, so the foregoing limitation may not apply to you.
        </span>
        <span className={styles.title}>Indemnity</span>
        <span className={styles.text}>
          You agree to indemnify and hold Sentinel, its officers, directors, shareholders, predecessors, successors in interest, employees, agents, subsidiaries and affiliates,
          harmless from any demands, loss, liability, claims or expenses (including attorneys’ fees), made against Sentinel by any third party due to or arising out of or in
          connection with your use of the Site.
        </span>
        <span className={styles.title}>Violation of These Terms of Use</span>
        <span className={styles.text}>
          Sentinel may disclose any information we have about you (including your identity) if we determine that such disclosure is necessary in connection with any investigation
          or complaint regarding your use of the Site, or to identify, contact or bring legal action against someone who may be causing injury to or interference with (either
          intentionally or unintentionally) Sentinel rights or property, or the rights or property of visitors to or users of the Site, including Sentinel customers. Sentinel
          reserves the right at all times to disclose any information that Sentinel deems necessary to comply with any applicable law, regulation, legal process or governmental
          request. Sentinel also may disclose your information when Sentinel determines that applicable law requires or permits such disclosure, including exchanging information
          with other companies and organizations for fraud protection purposes. You acknowledge and agree that Sentinel may preserve any transmittal or communication by you with
          Sentinel through the Site or any service offered on or through the Site, and may also disclose such data if required to do so by law or Sentinel determines that such
          preservation or disclosure is reasonably necessary to (1) comply with legal process, (2) enforce these Terms of Use, (3) respond to claims that any such data violates the
          rights of others, or (4) protect the rights, property or personal safety of Sentinel, its employees, users of or visitors to the Site, and the public. You agree that
          Sentinel may, in its sole discretion and without prior notice, terminate your access to the Site and/or block your future access to the Site if we determine that you have
          violated these Terms of Use or other agreements or guidelines which may be associated with your use of the Site. You also agree that any violation by you of these Terms
          of Use will constitute an unlawful and unfair business practice, and will cause irreparable harm to Sentinel, for which monetary damages would be inadequate, and you
          consent to Sentinel obtaining any injunctive or equitable relief that Sentinel deems necessary or appropriate in such circumstances. These remedies are in addition to any
          other remedies Sentinel may have at law or in equity. You agree that Sentinel may, in its sole discretion and without prior notice, terminate your access to the Site, for
          cause, which includes (but is not limited to) (1) requests by law enforcement or other government agencies, (2) a request by you (self-initiated account deletions), (3)
          discontinuance or material modification of the Site or any service offered on or through the Site, or (4) unexpected technical issues or problems. If Sentinel does take
          any legal action against you as a result of your violation of these Terms of Use, Sentinel will be entitled to recover from you, and you agree to pay, all reasonable
          attorneys’ fees and costs of such action, in addition to any other relief granted to Sentinel. You agree that Sentinel will not be liable to you or to any third party for
          termination of your access to the Site as a result of any violation of these Terms of Use.
        </span>
        <span className={styles.title}>Miscellaneous</span>
        <span className={styles.text}>
          If any of the provisions of these Terms of Use are held by a court or other tribunal of competent jurisdiction to be void or unenforceable, such provisions shall be
          limited or eliminated to the minimum extent necessary and replaced with a valid provision that best embodies the intent of these Terms of Use, so that these Terms of Use
          shall remain in full force and effect. These Terms of Use constitute the entire agreement between you and Sentinel with regard to your use of the Site, and any and all
          other written or oral agreements or understandings previously existing between you and Sentinel with respect to such use are hereby superseded and cancelled. Other than
          as provided in a purchase agreement you enter into with Sentinel, Sentinel will not accept any counter-offers to these Terms of Use, and all such offers are hereby
          categorically rejected. Sentinel failure to insist on or enforce strict performance of these Terms of Use shall not be construed as a waiver by Sentinel of any provision
          or any right it has to enforce these Terms of Use, nor shall any course of conduct between Sentinel and you or any other party be deemed to modify any provision of these
          Terms of Use. These Terms of Use shall not be interpreted or construed to confer any rights or remedies on any third parties. Sentinel provides access to Sentinel
          international data and, therefore, may contain references or cross references to Sentinel products, programs and services that are not announced in your country. Such
          reference does not imply that Sentinel in your country intends to announce such products, programs or services.
        </span>
        <span className={styles.title}>Feedback and Information</span>
        <span className={styles.text}>
          Any feedback you provide at this site shall be deemed to be non-confidential. Sentinel shall be free to use such information on an unrestricted basis. The information
          contained in this web site is subject to change without notice.
        </span>
        <span className={styles.text}>CAPYBARA PUBLISHING LTD. (reg no. 15786216)</span>
        <span className={styles.text}>85 Great Portland Street</span>
        <span className={styles.text}>London, England</span>
        <span className={styles.text}>W1W 7LT</span>
      </div>
    </div>
  );
};

export default TOS;
