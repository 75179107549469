import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TOS from "./pages/TOS";

const Header = React.lazy(() => import("./layout/Header"));
const Footer = React.lazy(() => import("./layout/Footer"));
const Main = React.lazy(() => import("./layout/Main"));
const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("header")).render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <Header />
        <Routes>
          <Route
            path="/"
            element={<Main />}
          />
          <Route
            path="/legal/privacy"
            element={<PrivacyPolicy />}
          />
          <Route
            path="/legal/tos"
            element={<TOS />}
          />
          <Route
            path="*"
            element={
              <Navigate
                to={"/"}
                replace={true}
              />
            }
          />
        </Routes>
        <Footer />
      </Suspense>
    </BrowserRouter>
  </QueryClientProvider>
);
